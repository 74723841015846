@use "sass:math" as math;
@use "global-variables" as *;

$dollhouseScaleFactor: 16 / 9;
$dollhouseScaleWidescreen: 9 / 16;

@function strip-unit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        @return math.div($number, ($number * 0 + 1));
    }

    @return $number;
}

@function vw($value) {
    $designWidth: 933;
    $vwEquivalent: calc(math.div(strip-unit($value), $designWidth) * 100vw);
    @return #{$vwEquivalent};
}

@function vw4k($value) {
    $designWidth: 3840;
    $vwEquivalent: math.div(strip-unit($value), $designWidth) * 100vw;
    @return #{$vwEquivalent};
}

/**
 * Converts px to rem based on the default font size
**/
@function rem($px) {
    $result: math.div(strip-unit($px), strip-unit($default-font-size)) * 1rem;

    $rounded: math.div(math.round($result * 1000), 1000);

    @return $rounded;
}

// Base value off of container width
@function cw($value) {
    $vcwEquivalent: cw-custom($value, 933);
    @return $vcwEquivalent;
}

// Base value off of container width
@function cw-4k($value) {
    $vcwEquivalent: cw-custom($value, 3840);
    @return $vcwEquivalent;
}

// This allows a passed in container width,
// this value should be based on the container width at the current design size
@function cw-custom($value, $containerWidth) {
    $vcwEquivalent: math.div(strip-unit($value), $containerWidth) * 100;
    @return #{$vcwEquivalent}cqw;
}

@function percent-of-container($value, $containerWidth) {
    $percentEquivalent: math.div(strip-unit($value), $containerWidth) * 100%;
    @return #{$percentEquivalent};
}
