// Define Breakpoint Map
$res-breakpoints: (
    ("xxs", 374px),
    ("xs", 480px),
    ("s", 624px),
    ("sm", 767px),
    ("m", 896px),
    ("md", 1024px),
    ("l", 1195px),
    ("lg", 1439px),
    ("hd", 1920px),
    ("4k", 3840)
);

// Create Breakpoint Mixins: First is to establish from a breakpoint and up;
// Second is to establish only to that breakpoint
// Third is to establish from a breakpoint down
@mixin respond-from($media) {
    @each $breakpoint in $res-breakpoints {
        @if $media == nth($breakpoint, 1) {
            @media (min-width: #{nth($breakpoint, 2) + 1px}) {
                @content;
            }
        }
    }
}

@mixin respond-to($media) {
    @for $i from 1 through length($res-breakpoints) {
        $breakpoint: nth($res-breakpoints, $i);
        $name: nth($breakpoint, 1);
        $value: nth($breakpoint, 2);

        @if $media == $name {
            @if $i == 1 {
                @media (max-width: #{$value - 1px}) {
                    @content;
                }
            } @else if $i == length($res-breakpoints) {
                @media (min-width: #{$value + 1px}) {
                    @content;
                }
            } @else {
                $next_breakpoint: nth($res-breakpoints, $i + 1);
                $next_name: nth($next_breakpoint, 1);
                $next_value: nth($next_breakpoint, 2);

                @media (min-width: #{$value + 1px}) and (max-width: #{$next_value - 1px}) {
                    @content;
                }
            }
        }
    }
}

@mixin respond-until($media) {
    @each $breakpoint in $res-breakpoints {
        @if $media == nth($breakpoint, 1) {
            @media (max-width: #{nth($breakpoint, 2)}) {
                @content;
            }
        }
    }
}

// Uses res-breakpoints to set styles for landscape orientation up to provided size
@mixin land-until($media) {
    @each $breakpoint in $res-breakpoints {
        @if $media == nth($breakpoint, 1) {
            @media (max-width: #{nth($breakpoint, 2)}) and (orientation: landscape) {
                @content;
            }
        }
    }
}

// Mixin to establish styling for IE only
@mixin ie-only {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}
