@use "functions" as *;
@use "mixins" as *;

// Handle inner html elements
.section__copy {
    a {
        color: var(--red-accent);
        text-decoration: underline;
        font-family: roboto-bold, sans-serif;
        text-decoration: none;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: var(--red-accent-hover);
        }
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .accordion-item {
        // Default global mobile only styles for accordion items
        @include respond-until(sm) {
            --accordion-header-height: #{vw(121px)};
            --accordion-header-padding: #{vw(38px)} #{vw(23px)} #{vw(38px)} #{vw(23px)};
            --accordion-indicator-font-size: #{vw(60px)};
            --accordion-indicator-width: #{vw(29.33px)};
            --accordion-indicator-line-height: 1.2;
            --accordion-indicator-spacing: #{vw(25px)};

            --accordion-heading-font-size: #{vw(40px)};
            --accordion-icon-shadow-width: #{vw(4px)};
            --accordion-heading-shadow-width: #{vw(6px)};
            --accordion-content-padding: #{vw(25px)} #{vw(50px)} #{vw(41px)};
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: vw(40px);

        @include respond-from(sm) {
            margin-bottom: 0.96vw;
            padding: 0 2.29vw 0 0.44vw;
        }
    }

    &__heading {
        margin-bottom: 0;
        font-family: roboto-black, sans-serif;
        color: var(--white);
    }
}

.c-legend-icon {
    display: flex;

    &--service {
        width: vw(60px);
        height: vw(60px);
        margin-bottom: vw(14px);

        @include respond-from(sm) {
            width: 2.08vw;
            height: 2.1vw;
            margin-bottom: 0.286vw;
        }
    }

    &--product {
        width: vw(70px);
        height: vw(70px);
        margin-bottom: vw(8px);

        @include respond-from(sm) {
            margin-bottom: 0;
            width: 2.42vw;
            height: 2.42vw;
        }
    }
}

.accordion-legend {
    $item: &;
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    padding-right: vw(23px);

    @include respond-from(sm) {
        padding-right: 0;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-bottom: cw(20px);

        @include respond-from(sm) {
            height: max(40px, #{vw4k(127px)});
            padding-bottom: max(5px, 0.42vw);
        }

        &:nth-child(n + 2) {
            margin-left: max(20px, 1.4vw);

            @include respond-from(sm) {
                margin-left: max(30px, #{vw4k(18px)});
            }
        }
    }

    &--no-labels {
        padding-right: 0;

        #{$item}__item {
            height: unset;
            padding-bottom: 0 !important;
        }
    }

    &__text {
        position: absolute;
        bottom: calc(-1 * #{vw(10px)});
        font-size: max(11px, #{vw(20px)});

        @include respond-from(sm) {
            bottom: calc(-1 * #{vw4k(5px)});
            font-size: max(12px, #{vw4k(16px)});
        }

        color: var(--white);
    }
}

grainger-accordion-item {
    margin-bottom: vw(4px);

    @include respond-from(sm) {
        margin-bottom: vw4k(17px);
    }
}

.accordion-content {
    &__subtitle {
        display: flex;
        align-items: center;
        font-size: cw(48px);
        font-family: roboto-black, sans-serif;
        min-height: cw(56px);
        margin-bottom: cw(14px);

        @include respond-from(sm) {
            margin-bottom: 0;
            font-size: 1.88vw;
            min-height: 4.24vw;
        }

        & + h4 {
            font-size: cw(36px);
            min-height: cw(48px);
            font-style: italic;

            @include respond-from(sm) {
                font-size: 1.6vw;
                min-height: 4vw;
            }
        }
    }

    &__copy {
        font-size: cw(30px);
        font-family: roboto, sans-serif;
        margin-bottom: vw(40px);

        @include respond-from(sm) {
            margin-bottom: vw4k(40px);
            font-size: 1.04vw;
        }
    }

    &__links {
        font-size: cw(30px);
        margin-bottom: cw(41px);
        font-family: roboto-bold, sans-serif;

        @include respond-from(sm) {
            font-size: 1.04vw;
            margin-bottom: 0.96vw;
        }
    }

    &__link-item {
        display: block;
        --icon-spacing: #{cw(8px)};
        --icon-line-height: 1.63;
        --link-line-height: 1.63;
        --link-text-transform: uppercase;
        --link-font-family: roboto, sans-serif;
        --link-color: var(--black);
        --link-color-active: var(--red-accent);
        --link-font-family-active: roboto-bold, sans-serif;
        --icon-font-family-active: roboto-bold, sans-serif;
        --icon-color: var(--black);
        --icon-color-active: var(--red-accent);
        margin-bottom: cw(28px);

        @include respond-from(sm) {
            margin-bottom: 0;
            --icon-spacing: 0.52vw;
        }
    }

    &__button {
        --text-spacing: 0.98vw;
        --arrow-button-padding: 1.09vw 2.06vw 1.22vw 2.16vw;
        --arrow-button-bg-color: var(--red-accent);
        --arrow-button-bg-color-active: var(--red-accent);
    }
}

.arrow-resource-link {
    $link: &;
    display: flex;
    align-items: flex-start;
    margin-bottom: vw(40px);
    font-size: vw(48px);
    color: var(--white);
    transition: color 0.2s ease-in-out;
    text-decoration: none;
    letter-spacing: 0.01em;
    transition: font-family 0.05s ease-in-out;

    &:hover {
        color: var(--red-accent);
        font-family: roboto-black, sans-serif;

        #{$link}__icon {
            background-color: var(--red-accent);
        }
    }

    @include respond-from(sm) {
        align-items: center;
        margin-bottom: vw4k(40px);
        font-size: vw4k(48px);
    }

    &__icon {
        display: flex;
        margin-top: vw(10px);
        margin-right: vw(20px);
        background-color: var(--white);
        transition: background-color 0.05s ease-in-out;
        width: vw(40px);
        height: vw(40px);

        -webkit-mask-image: url(/assets/ui/icon-link-arrow-right.svg);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        -webkit-mask-position: center;

        mask-image: url(/assets/ui/icon-link-arrow-right.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;

        @include respond-from(sm) {
            margin-top: 0;
            margin-right: vw4k(20px);
            width: vw4k(40px);
            height: vw4k(40px);
        }
    }
}

.bullet-list,
.link-list {
    padding-left: vw(55px);
    color: inherit;
    width: 100%;

    @include respond-from(sm) {
        padding-left: vw4k(70px);
    }

    &__item {
        display: list-item;
        margin-bottom: vw(20px);
        font-size: vw(50px);

        @include respond-from(sm) {
            margin-bottom: vw4k(40px);
            font-size: vw4k(50px);
        }
    }

    &.dash-list {
        list-style-type: "- ";
    }
}

.bullet-list {
    list-style-type: disc;
}

.link-list {
    &__item {
        list-style-type: "+ ";
        transition: all 0.05s ease-in-out;
        font-family: var(--link-font-family, inherit);
        font-size: var(--link-font-size, 1em);
        line-height: var(--link-line-height, 1.5em);
        white-space: var(--link-text-whitespace, pre-line);
        text-shadow: 0 var(--link-text-shadow-length, 0) var(--link-text-shadow-length, 0)
            var(--link-shadow-color, rgba(0, 0, 0, 0.25));
        overflow-wrap: var(--link-overflow-wrap, anywhere);
        color: inherit;
        font-family: var(--link-font-family, inherit);
        --link-font-family-active: roboto-bold, sans-serif;
        --link-color: inherit;
        --link-color-active: var(--red-accent);
        --icon-color-active: var(--red-accent);
        --link-line-height: normal;
        --icon-font-size: vw(60px);
        --icon-font-size-active: vw(60px);
        --align-items: flex-start;

        &:hover,
        &:active,
        &:focus,
        &.active {
            font-family: var(--link-font-family-active, inherit);
            color: var(--link-color-active, inherit);
            text-shadow: 0 var(--link-icon-shadow-length-active, 0) var(--link-icon-shadow-length-active, 0)
                var(--link-shadow-color, rgba(0, 0, 0, 0.25));
        }
    }
}
