@use "global-styles" as *;
@use "mixins" as *;

@forward "node_modules/@ng-select/ng-select/scss/default.theme.scss";

/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
    padding: 0;
    background-color: black;
    color: #fff;
    font-family: roboto, sans-serif;
    line-height: 1.17;
}

.page-content {
    border-radius: 0.78vw;
    border-top-left-radius: 0;
    width: 100%;
    background-color: var(--black);
}

.screen {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(48, 15, 15, 0.85) 80%, #000 100%);
    backdrop-filter: blur(2px);
    mix-blend-mode: hard-light;
}

.mobile-only {
    @include respond-from(sm) {
        display: none !important;
    }
}

.desktop-only {
    @include respond-until(sm) {
        display: none !important;
    }
}

.ratio-container {
    container-type: size;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100vw;
    height: 56.25vw;
    max-width: calc((1920 / 1080) * 100vh);
    max-height: 100vh;
    margin: 0 auto;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
