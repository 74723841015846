@use "functions" as *;
@use "mixins" as *;

.detail-back-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 160%;
        height: 110%;
        transform: translate(-25%, -5%);
    }

    &__icon {
        position: relative;
        display: block;
        width: vw(50.13px);
        height: vw(80px);
        background-color: var(--white);
        -webkit-mask-image: url(/assets/ui/icon-chevron-left.svg);
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-image: url(/assets/ui/icon-chevron-left.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
    }

    &__text {
        margin-left: vw(15px);
        font-size: vw(60px);
        color: var(--white);
    }
}

.tab-content {
    padding: vw(40px) vw(40px) vw(150.5px);

    @include respond-from(sm) {
        padding: 2.68vw vw4k(198px) 2.99vw;
    }

    .question-list {
        margin-bottom: vw4k(169px);
        font-size: vw4k(75px);
        --link-font-size: vw4k(75px);
        --link-line-height: 1.2;

        &__item {
            margin-bottom: vw4k(36px);
        }
    }

    .logo {
        margin: 0 auto;
        width: vw(506px);
        height: auto;

        @include respond-from(sm) {
            width: vw4k(545.319px);
        }
    }

    &.can-edit {
        * {
            list-style-type: none !important;
        }

        ul {
            padding-left: 0 !important;
        }

        .bullet-list {
            .list-item-edit {
                transform: translateX(#{vw(-46px)});
            }
        }
    }
}

.section {
    margin-bottom: vw(140px);

    @include respond-from(sm) {
        margin-bottom: vw4k(235px);
    }

    &__heading,
    &__content {
        color: var(--white);
    }

    &__heading {
        font-family: roboto-black, sans-serif;
        font-size: vw(60px);
        margin-bottom: vw(40px);

        @include respond-from(sm) {
            margin-bottom: vw4k(50px);
            font-size: vw4k(85px);
        }

        &--small {
            font-size: vw(60px);

            @include respond-from(sm) {
                font-size: vw4k(72px);
            }
        }

        &--large {
            font-size: vw(85px);

            @include respond-from(sm) {
                font-size: vw4k(100px);
            }
        }
    }

    &__subheading {
        font-family: roboto-bold, sans-serif;
        font-size: vw4k(72px);
    }

    &__content {
        font-size: vw(40px);

        @include respond-from(sm) {
            font-size: vw4k(50px);
        }
    }
}
