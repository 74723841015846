@use "functions"as *;
@use "mixins"as *;

.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &__heading {
        font-family: roboto-black, sans-serif;
        font-size: cw(80px);
        margin-bottom: cw(60px);
        color: var(--white);

        @include respond-from(sm) {
            margin-bottom: cw-4k(150px);
            font-size: cw-4k(180px);
        }
    }

    .c-buttons {
        --arrow-button-font-size: #{cw(50px)};

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: cw(40px);

        @include respond-from(sm) {
            align-items: center;
            flex-direction: row;
            margin-bottom: cw-4k(50px);

            --arrow-button-font-size: #{cw-4k(130px)};
            --arrow-button-text-shadow-length: #{cw-4k(25px)};
            --arrow-button-padding: #{cw-4k(78px)} #{cw-4k(97px)} #{cw-4k(79px)} #{cw-4k(92px)};
            --arrow-button-border-radius: #{cw-4k(70px)};
            --button-height: #{cw-4k(275px)};
            --text-spacing: #{cw-4k(55px)};
            --arrow-icon-width: #{cw-4k(135px)};
            --arrow-icon-height: #{cw-4k(93.87px)};
        }

        :last-child,
        &:last-child {
            margin-bottom: 0;
        }
    }

    .c-form-fields {
        margin-bottom: cw-4k(80px);
    }
}

grainger-form-control {
    width: 100%;
    display: block;
    margin-bottom: cw(30px);

    @include respond-from(sm) {
        margin-bottom: cw-4k(60px);
    }

    .select-toggle,
    input {
        width: 100%;
        height: 100%;
        font-size: cw(40px);
        font-family: roboto-bold, sans-serif;
        padding: cw(18px) 0 cw(18px) cw(50px);
        border-radius: inherit;

        outline: none !important;
        border: none;

        @include respond-from(sm) {
            font-size: cw-4k(90px);
            padding: cw-4k(48px) cw-4k(40px) cw-4k(48px) cw-4k(105px);
        }

        &::placeholder {
            color: rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
        }
    }
}

.submission-error {
    color: var(--red-accent);
}

.submission-success {
    color: var(--white);
}

.submission-success,
.submission-error {
    font-size: cw(40px);
    font-weight: 500;

    @include respond-from(sm) {
        font-size: cw-4k(90px);
    }
}

.form-change-link {
    font-size: cw(40px);
    color: var(--white);

    display: flex;
    flex-direction: column;

    @include respond-from(sm) {
        font-size: cw-4k(90px);
        margin-left: auto;
    }

    &__label,
    &__text {
        color: inherit;
    }

    &__label {
        margin-bottom: cw(20px);

        @include respond-from(sm) {
            margin-bottom: cw-4k(40px);
        }
    }
}

/* ---- inline editor styles ----  */

.inline-editable {
    position: relative;

    &.inline-editing {
        display: flex;

        .inline-input {
            flex: 1;
        }

        .inline-edit-buttons {
            width: auto;
        }

    }
}

.inline-input {
    display: block;
    width: 100%;
    color: #000;
}

.inline-edit-icon,
.inline-trash-icon {
    position: absolute;
    width: vw(12px);
    height: vw(12px);
    left: vw(-22px);
    top: 0;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    transition: all 0.2s ease-in-out;


    &:hover,
    &:active,
    &:focus {
        color: var(--button-color-active);
    }
}

.inline-trash-icon {
    left: vw(-40px);
}

.inline-edit-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: vw(4px);
    width: 100%;
}

.inline-edit-cancel {
    cursor: pointer !important;
    background: transparent !important;
    border: none;
    padding: vw(4px) vw(8px) !important;
    height: auto !important;
    color: inherit !important;
    border-radius: vw(30px) !important;
    transition: all 0.2s ease-in-out !important;
    font-size: vw(10px) !important;

    &:hover,
    &:active,
    &:focus {
        color: var(--button-color-active);
    }
}


.inline-edit-button {
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--button-color) !important;
    border-radius: vw(30px) !important;
    padding: vw(4px) vw(8px) !important;
    height: auto !important;
    color: var(--white) !important;
    transition: all 0.2s ease-in-out !important;
    font-size: vw(10px) !important;


    &:hover,
    &:active,
    &:focus {
        background: var(--button-color-active);
    }
}


.placeholder {
    color: rgba(255, 255, 255, 0.5);
    --link-color: rgba(255, 255, 255, 0.5);
    font-style: italic;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    .list-item__text,
    &.add-item {
        transition: all 0.2s ease-in-out;

        &:hover {
            color: var(--button-color-active);
        }
    }

    &.inline-editable {
        &:hover {
            color: var(--button-color-active);
        }
    }
}

.accordion-item__content {
    .placeholder {
        color: rgba(0, 0, 0, 0.5);
        --link-color: rgba(0, 0, 0, 0.5);
    }
}

.accordion-item__heading {
    .inline-editable {
        margin-left: vw(22px);
    }

    .inline-trash-icon {
        left: vw(-80px);
    }
}

.supplier__phone {
    .inline-edit-icon {
        left: vw(-60px);
    }
}

/* ---- ng-select styles ----  */

.ng-select.inline-add {

    &:not(.ng-select-opened) {
        cursor: pointer;

        .ng-select-container,
        .ng-placeholder,
        .ng-input input {
            cursor: pointer !important;
        }

        .ng-placeholder {
            transition: all 0.2s ease-in-out;

        }

        &:hover {
            .ng-placeholder {
                color: var(--button-color-active);

            }
        }
    }

    .ng-select-container {
        border: none;
        border-radius: 0;
        background-color: transparent;
        color: inherit;
    }

    .ng-placeholder {
        font-style: italic;

    }

    .ng-arrow-wrapper {
        display: none;
    }

    .ng-input {}

    .ng-dropdown-panel,
    .ng-option {
        border-radius: 0 !important;
    }

}