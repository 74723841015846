@use "functions"as *;
@use "mixins"as *;

.c-popover {
    position: fixed;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.35);
    padding: vw(22px);
}

.popover {
    position: fixed;
    z-index: 1000;
    display: block;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    color: #FFF;
    padding: vw(22px);
    border-radius: vw(12px);

    &-header {
        margin-bottom: vw(22px);
        padding-bottom: vw(8px);
        border-bottom: 1px solid #FFF;

        h3 {
            font-size: vw(18px);
            font-weight: 700;
            // margin-bottom: vw(12px);
        }

        p {
            font-size: vw(14px);
            font-weight: 400;
        }

    }

    &-footer {
        margin-top: vw(22px);
        padding-top: vw(22px);
        border-top: 1px solid #FFF;
        display: flex;
        justify-content: flex-end;
        gap: vw(4px);

    }

    &-body {
        margin: vw(22px) 0;
        flex: 1;

        p,
        ul {
            margin-bottom: vw(12px);
        }

    }

}