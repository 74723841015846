// Dropdown hover state must be from global context since button is projected into component
@use "functions" as *;
@use "mixins" as *;

.dropdown {
    $dropdown: &;

    &__trigger {
        cursor: pointer;

        #{$dropdown}__content {
            right: 0;

            &::before {
                content: "";
                position: absolute;
                top: calc(-1 * var(--offset-top) / 2);
                right: vw(38px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 vw(17px) vw(31px) vw(17px);
                border-color: transparent;
                opacity: 0;
                transition: opacity 0.1s ease-in;
                z-index: -1;

                @include respond-from(sm) {
                    top: calc(-1 * var(--offset-top, 0.7vw));
                    right: 0;
                    transform: translateX(-100%);
                    border-width: 0 0.65vw 1.38vw 0.65vw;
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
            .btn {
                background: var(--button-color-active);
                color: var(--black);
            }

            .dropdown__content {
                &::before {
                    animation: fadeIn 0.1s ease-in forwards;
                }

                &--below {
                    --offset-top: #{vw(57px)};
                    --offset-left: 0;

                    @include respond-from(sm) {
                        --offset-top: 0.7vw;
                    }

                    &::before {
                        border-color: transparent transparent
                            var(
                                --dropdown-tooltip-background-color,
                                var(--dropdown-background-color, var(--button-color-active))
                            )
                            transparent;
                    }
                }

                &--right {
                    --offset-left: 1.38vw;
                    --arrow-offset: 0.73vw;
                    &::before {
                        border-width: 0.65vw 1.38vw 0.65vw 0;
                        border-color: transparent
                            var(
                                --dropdown-tooltip-background-color,
                                var(--dropdown-background-color, var(--button-color-active))
                            )
                            transparent transparent;
                        right: unset;
                        left: 0;
                        transform: translateX(calc(-1 * var(--arrow-offset)));
                    }
                }
            }
        }
    }
}

.aligned-with-container {
    .dropdown__trigger {
        .btn--plus-sign {
            background: var(--black);
            color: var(--white);
            border: 2px solid var(--white);
        }

        &:hover,
        &:focus,
        &:active {
            .btn--plus-sign {
                background: var(--white);
                color: var(--black);
            }
        }
    }
}
