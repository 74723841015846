@font-face {
    font-family: roboto-light;
    src: url("../assets/fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: roboto-condensed-light;
    src: url("../assets/fonts/RobotoCondensed-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: roboto;
    src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: roboto-condensed;
    src: url("../assets/fonts/RobotoCondensed-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: roboto-medium;
    src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: roboto-bold;
    src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: roboto-condensed-bold;
    src: url("../assets/fonts/RobotoCondensed-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: roboto-black;
    src: url("../assets/fonts/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
}
