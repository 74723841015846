@use "./mixins"as *;
@use "./functions"as *;

.icon-with-text {
    display: grid;
    grid-template-columns: vw(80px) 1fr;
    column-gap: vw(60px);
    align-items: center;

    @include respond-from(sm) {
        grid-template-columns: 2.6vw 1fr;
        column-gap: 1.61vw;
    }

    .icon {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .text {
        font-family: var(--font-family, roboto-condensed, sans-serif);
        font-size: var(--text-font-size, #{vw(60px)});

        @include respond-from(sm) {
            font-size: var(--text-font-size, 1.95vw);
        }
    }

    .inline-edit-icon {
        left: vw(-60px);

    }

    .inline-trash-icon {
        left: vw(-80px);
    }
}