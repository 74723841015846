@use "animations"as *;
@use "functions"as *;
@use "mixins"as *;

.btn {
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &-accent,
    &-clear {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--button-color);
        border-radius: vw(30px);
        padding: vw(4px) vw(8px);
        height: auto;
        transition: all 0.2s ease-in-out;
        color: var(--white);

        &:hover,
        &:active,
        &:focus {
            background: var(--button-color-active);
        }
    }

    &-clear {
        --button-color: transparent;
        --button-color-active: rgba(255, 255, 255, 0.1);
    }
}

.nav-button-toggle {
    background-color: transparent;

    z-index: 10;

    &__icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.form-btn {
    margin-bottom: cw(50px);

    @include respond-from(sm) {
        margin-bottom: 0;
    }
}