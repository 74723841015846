@use "functions" as *;
@use "mixins" as *;

:root {
    --black: #000000;
    --white: #ffffff;

    --red-accent: #c1161a;

    --muted-red: rgba(168, 29, 40, 1);

    --button-color: rgba(193, 22, 26, 0.79);

    --button-color-active: var(--red-accent);

    --muted-red-transparent: rgba(204, 32, 48, 0.79);

    --input-height: #{cw(120px)};
    --input-border-radius: #{cw(25px)};

    @include respond-from(sm) {
        --input-height: #{cw-4k(300px)};
        --input-border-radius: #{cw-4k(70px)};
    }
}
